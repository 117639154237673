import { motion } from 'framer-motion';
import React from 'react';
import arrowButton from '../../../assets/images/banner/arrow-bottom.png';
import ImageWithBadge from '../ImageWithBadge/ImageWithBadge';
import './MDailyHome.scss';
import useIsDesktop from '../../../hooks/useIsDesktop';

const MDailyHome = () => {
  const openLinkInNewTab = (url) => {
    window.open(url, '_blank').focus();
  };

  const items = [
    {
      src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/mdaily(1).png",
      alt: "New Image",
      badgeText: "M/DAILY",
      captionText: "Como fechar o buraco do conhecimento em IA?",
      link: "https://fabiovagnerribeiro.medium.com/ia-afaste-de-mim-esse-c%C3%A1lice-8480fd4b9b92"
    },
    {
      src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+insta+_+m_daily+(2).png",
      alt: "New Image",
      badgeText: "M/DAILY",
      captionText: "Quando tudo é IA, para onde vamos?",
      link: "https://fabiovagnerribeiro.medium.com/menos-tecnologia-por-favor-c3c5ae7cc52c"
    },
    {
      src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+insta+%2B+lnkd+_+m_daily.png",
      alt: "New Image",
      badgeText: "M/DAILY",
      captionText: "De proibição em proibição, mais perto da extinção",
      link: "https://fabiovagnerribeiro.medium.com/proibi%C3%A7%C3%A3o-faz-eco-mas-%C3%A9-oca-13d0a94da875"
    },
    {
      src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+insta+_+m_daily+(3)%402x.png",
      alt: "New Image",
      badgeText: "M/DAILY",
      captionText: "Survival thinking",
      link: "https://fabiovagnerribeiro.medium.com/pensamento-de-sobreviv%C3%AAncia-708432c44f9f"
    },
    {
      src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/two-m-daily.png",
      alt: "New Image",
      badgeText: "M/DAILY",
      captionText: "Uma I.A. sem cores",
      link: "https://fabiovagnerribeiro.medium.com/uma-vida-mastigando-apenas-br%C3%B3colis-c9b2a70b9291"
    },
    {
      src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+insta+_+m_daily+(4).png",
      alt: "New Image",
      badgeText: "M/DAILY",
      captionText: "O clique mental e o custo por clique",
      link: "https://fabiovagnerribeiro.medium.com/n%C3%A3o-s%C3%A3o-apenas-as-%C3%A1guas-de-mar%C3%A7o-05f0060ccf3f"
    },
    {
      src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+lnkd+_+m_daily.png",
      alt: "New Image",
      badgeText: "M/DAILY",
      captionText: "Abafadas pela FIFA",
      link: "https://fabiovagnerribeiro.medium.com/pelo-fim-da-prorroga%C3%A7%C3%A3o-e-das-penalidades-65f1048fa220"
    },
    {
      src: "https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/mdailys/arte+_+lnkd+_+m_daily+(1).png",
      alt: "New Image",
      badgeText: "M/DAILY",
      captionText: "Quem vive pelo canto não emudece jamais",
      link: "https://fabiovagnerribeiro.medium.com/cont%C3%A9m-celine-13cda9a23c86"
    },
  ];

  const firstSet = items.slice(0, 4);
  const secondSet = items.slice(4);
  const isDesktop = useIsDesktop();

  return (
    <div className='MDailyHome'>
      <div className="mdaily-home-content">
        {isDesktop ? (
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
          >
            <motion.div
              initial={{ x: "-200px", opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: "-20vw", opacity: 0 }}
              transition={{ duration: 0.9, ease: "easeInOut" }}
              viewport={{ once: true }}
            >
              <p className="flutuant-text">FIQUE AGORA COM OS ÚLTIMOS M/DAILYS</p>
            </motion.div>

            <div className="container-mdaily">
              <motion.div
                className="box-mdaily"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeInOut" }}
                viewport={{ once: true }}
              >
                {firstSet.map((item, index) => (
                  <div key={index} className="box">
                    <ImageWithBadge
                      src={item.src}
                      alt={item.alt}
                      mdaily
                      badgeText={item.badgeText}
                      captionText={item.captionText}
                      size='100%'
                      width='100%'
                      height='372px'
                      onClick={() => openLinkInNewTab(item.link)}
                    />
                  </div>
                ))}
              </motion.div>

              <motion.div
                className="box-mdaily"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1, ease: "easeInOut", delay: 0.5 }}
                viewport={{ once: true }}
              >
                {secondSet.map((item, index) => (
                  <div key={index + 4} className="box">
                    <ImageWithBadge
                      src={item.src}
                      alt={item.alt}
                      mdaily
                      badgeText={item.badgeText}
                      captionText={item.captionText}
                      size='100%'
                      width='100%'
                      height='372px'
                      onClick={() => openLinkInNewTab(item.link)}
                    />
                  </div>
                ))}
              </motion.div>
            </div>

            <motion.div
              className="button-daily"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.4 }}
              viewport={{ once: true }}
            >
              <button>
                assine de graça.
              </button>
            </motion.div>

            <motion.div
              className="container-apresentation-mdaily"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.4 }}
              viewport={{ once: true }}
            >
              <h3>Isso não é tudo, folks.</h3>
              <div className="circular-text-container">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className="circle-svg">
                  <defs>
                    <path
                      id="circlePath"
                      d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
                    />
                  </defs>
                  <text className="circle-text">
                    <textPath href="#circlePath" textAnchor="middle" startOffset="50%">
                      conhecimento em movimento
                    </textPath>
                  </text>
                </svg>
                <motion.img
                  src={arrowButton}
                  alt="arrowButton"
                  className='arrow'
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  viewport={{ once: true }}
                />
              </div>
            </motion.div>
          </motion.div>
        ) : (
          <div className="mdaily-home-content">
            <div className="flutuant-text">FIQUE AGORA COM OS ÚLTIMOS M/DAILYS</div>

            <div className="container-mdaily">
              <div className="box-mdaily">
                {firstSet.map((item, index) => (
                  <div key={index} className="box">
                    <ImageWithBadge
                      src={item.src}
                      alt={item.alt}
                      mdaily
                      badgeText={item.badgeText}
                      captionText={item.captionText}
                      size='100%'
                      width='100%'
                      height='372px'
                      onClick={() => openLinkInNewTab(item.link)}
                    />
                  </div>
                ))}
              </div>

              <div className="box-mdaily">
                {secondSet.map((item, index) => (
                  <div key={index + 4} className="box">
                    <ImageWithBadge
                      src={item.src}
                      alt={item.alt}
                      mdaily
                      badgeText={item.badgeText}
                      captionText={item.captionText}
                      size='100%'
                      width='100%'
                      height='372px'
                      onClick={() => openLinkInNewTab(item.link)}
                    />
                  </div>
                ))}
              </div>
            </div>

            <div className="button-daily">
              <button>
                assine de graça.
              </button>
            </div>

            <div className="container-apresentation-mdaily">
              <h3>Isso não é tudo, folks.</h3>
              <div className="circular-text-container">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className="circle-svg">
                  <defs>
                    <path
                      id="circlePath"
                      d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
                    />
                  </defs>
                  <text className="circle-text">
                    <textPath href="#circlePath" textAnchor="middle" startOffset="50%">
                      conhecimento em movimento
                    </textPath>
                  </text>
                </svg>
                <img
                  src={arrowButton}
                  alt="arrowButton"
                  className='arrow'
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MDailyHome;
