import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import amImg from "../../../assets/images/cases/all/belts.webp";
import heinekenImg from "../../../assets/images/cases/all/heineken.webp";
import bayer from "../../../assets/images/partners/bayer.svg";
import globo from "../../../assets/images/partners/globo.svg";
import heineken from "../../../assets/images/partners/heineken.png";
import ibm from "../../../assets/images/partners/ibm.svg";
import itau from "../../../assets/images/partners/itau.svg";
import nestle from "../../../assets/images/partners/nestle.svg";
import santander from "../../../assets/images/partners/santander.svg";
import vivo from "../../../assets/images/partners/vivo.svg";
import useIsDesktop from "../../../hooks/useIsDesktop";
import ImageWithBadge from "../ImageWithBadge/ImageWithBadge";
import "./FeedCases.scss";

const FeedCases = () => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const navigateCase = (id) => {
    window.scrollTo(0, 0);
    navigate(`/cases/completed/${id}`);
  };

  const partners = [
    { name: "Heineken", image: heineken },
    { name: "Globo", image: globo },
    { name: "Bayer", image: bayer },
    { name: "IBM", image: ibm },
    { name: "Santander", image: santander },
    { name: "Vivo", image: vivo },
    { name: "Nestle", image: nestle },
    { name: "Itaú", image: itau },
  ];

  const fadeInUp = {
    hidden: { opacity: 0, y: 50 },
    visible: () => (isDesktop ? {
      opacity: 1,
      y: 0,
      transition: { duration: 1.4, ease: "easeInOut" },
    } : { opacity: 1, y: 0 }),

  };

  const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: () => (isDesktop ? {
      opacity: 1,
      x: 0,
      transition: { duration: 1.4, ease: "easeInOut" },
    } : { opacity: 1, y: 0, x: 0 }),
  };

  const fadeInRight = {
    hidden: { opacity: 0, x: 100 },
    visible: () => (isDesktop ? {
      opacity: 1,
      x: 0,
      transition: { duration: 1.4, ease: "easeInOut" },
    } : { opacity: 1, y: 0, x: 0 }),
  };

  return (
    <motion.div
      className="feedcases-container"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.4 }}
    >
      <div className="FeedCases">
        <motion.div
          className="left-cases"
          variants={fadeInLeft}
        >
          <p className="flutuant-text">OS CASES DA ESCOLA</p>
        </motion.div>

        <div className="container-cases">
          <motion.div variants={fadeInUp}>
            <ImageWithBadge
              src="https://mastertech-principal.s3.sa-east-1.amazonaws.com/new-site/case2.png"
              alt="New Image"
              badgeText="CASE"
              titleText="GLOBO | Desafio LED - Me dá uma luz aí."
              captionText="Nosso programa de empreendedorismo social"
              size="100%"
              width="100%"
              height="70vh"
              onClick={() => navigate("/case/3")}
            />
          </motion.div>

          <div className="box-cases">
            <motion.div
              className="left-cases"
              variants={fadeInLeft}
            >
              <ImageWithBadge
                src={amImg}
                alt="New Image"
                badgeText="CASE"
                titleText="A&M |Os belts de conhecimento"
                captionText="Six sigma, design thinking e mindset ágil"
                size="100%"
                width="100%"
                height="30vh"
                onClick={() => navigate("/case/1")}
              />
            </motion.div>

            <motion.div
              className="right-cases"
              variants={fadeInRight}
            >
              <ImageWithBadge
                src={heinekenImg}
                alt="New Image"
                badgeText="CASE"
                titleText="HEINEKEN | Transformação digital"
                captionText="Cultura de dados e data driven mindset"
                size="100%"
                width="100%"
                height="30vh"
                onClick={() => navigate("/case/2")}
              />
            </motion.div>
          </div>
        </div>

        <motion.div
          className="container-partners"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.3 }}
        >
          {partners.map((partner, index) => (
            <motion.img
              key={index}
              src={partner.image}
              alt={partner.name}
              className="partner-image"
              custom={index}
              variants={{
                hidden: { opacity: 0, y: 30 },
                visible: (i) => (isDesktop ? {
                  opacity: 1,
                  y: 0,
                  transition: {
                    delay: i * 0.2,
                    duration: 0.8,
                    ease: "easeInOut",
                  },
                } : { opacity: 1, y: 0 }),
              }}
            />
          ))}
        </motion.div>
      </div>
    </motion.div>
  );
};

export default FeedCases;
