import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/common/Loading/Loading';
import { replaceWithLineBreaks } from '../../utils/formating';
import './CaseDetails.scss';
import buildingListDetails from './caseListDetails';
import useIsDesktop from '../../hooks/useIsDesktop';

const CaseDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [contentBuilding, setContentBuilding] = useState(null);
  const [loading, setLoading] = useState(false);
  const isDesktop = useIsDesktop();

  const fetchBuildingDetails = async (id) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      const result = buildingListDetails.find((building) => building.id === id);
      setTimeout(() => {
        if (result) {
          resolve(result);
          setLoading(false);
        } else {
          setLoading(false);
          reject(new Error("Detalhes do building não encontrados."));
        }
      }, 500);
    });
  };

  useEffect(() => {
    const getBuildingDetails = async () => {
      try {
        const result = await fetchBuildingDetails(id);
        setContentBuilding(result);
      } catch (error) {
        console.error(error.message);
        navigate("/team-buildings");
      }
    };

    getBuildingDetails();
  }, [id, navigate]);

  const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: () => (isDesktop ? {
      opacity: 1,
      x: 0,
      transition: { duration: 1.4, ease: "easeInOut" },
    } : { opacity: 1, x: 0 }),
  };

  return (
    <>
      <div className='CaseDetails'>
        <div className="container-case-details">
          <motion.div
            className="header-case-details"
            initial={isDesktop ? { opacity: 0, y: 50 } : {}}
            whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
            transition={isDesktop ? { duration: 1, ease: "easeOut" } : {}}
            viewport={isDesktop ? { once: true } : {}}
          >
            <p className="title">{contentBuilding?.title}</p>
            <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.description) }} />
          </motion.div>

          <motion.div
            className="content"
            initial={isDesktop ? { opacity: 0 } : {}}
            whileInView={isDesktop ? { opacity: 1 } : {}}
            transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
            viewport={isDesktop ? { once: true } : {}}
          >
            <div className="left">
              <motion.p
                variants={fadeInLeft}
                initial="hidden"
                whileInView="visible"
                viewport={isDesktop ? { once: true } : {}}
                dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.left?.textOne) }}
              >
              </motion.p>
              {contentBuilding?.left?.imageOne && (
                <motion.img
                  src={contentBuilding?.left?.imageOne}
                  alt="Imagem 1"
                  initial={isDesktop ? { opacity: 0 } : {}}
                  whileInView={isDesktop ? { opacity: 1 } : {}}
                  transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                  viewport={isDesktop ? { once: true } : {}}
                />
              )}
              {contentBuilding?.left?.textTwoNegrito && <motion.span
                initial={isDesktop ? { opacity: 0 } : {}}
                whileInView={isDesktop ? { opacity: 1 } : {}}
                transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                viewport={isDesktop ? { once: true } : {}}
              >
                {contentBuilding?.left?.textTwoNegrito}
              </motion.span>}
              {contentBuilding?.left?.textTwo && <motion.p
                initial={isDesktop ? { opacity: 0 } : {}}
                className='text-two'
                whileInView={isDesktop ? { opacity: 1 } : {}}
                transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                viewport={isDesktop ? { once: true } : {}}
              >
                {contentBuilding?.left?.textTwo}
              </motion.p>}
              {contentBuilding?.left?.imageTwo && (
                <motion.img
                  src={contentBuilding?.left?.imageTwo}
                  alt="Imagem 2"
                  className='image-two'
                  initial={isDesktop ? { opacity: 0 } : {}}
                  whileInView={isDesktop ? { opacity: 1 } : {}}
                  transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                  viewport={isDesktop ? { once: true } : {}}
                />
              )}
            </div>

            <div className="right">
              {contentBuilding?.right?.imageOne && (
                <motion.img
                  src={contentBuilding?.right?.imageOne}
                  alt="Imagem 1"
                  initial={isDesktop ? { opacity: 0 } : {}}
                  whileInView={isDesktop ? { opacity: 1 } : {}}
                  transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                  viewport={isDesktop ? { once: true } : {}}
                />
              )}
              {contentBuilding?.right?.textOne && <motion.p
                initial={isDesktop ? { opacity: 0 } : {}}
                whileInView={isDesktop ? { opacity: 1 } : {}}
                transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                viewport={isDesktop ? { once: true } : {}}
              >
                {contentBuilding?.right?.textOne}
              </motion.p>}
              {contentBuilding?.right?.imageTwo && (
                <motion.img
                  src={contentBuilding?.right?.imageTwo}
                  alt="Imagem 2"
                  initial={isDesktop ? { opacity: 0 } : {}}
                  whileInView={isDesktop ? { opacity: 1 } : {}}
                  transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                  viewport={isDesktop ? { once: true } : {}}
                />
              )}
              {contentBuilding?.right?.textTwo && <motion.p
                initial={isDesktop ? { opacity: 0 } : {}}
                whileInView={isDesktop ? { opacity: 1 } : {}}
                transition={isDesktop ? { duration: 1, delay: 0.4 } : {}}
                viewport={isDesktop ? { once: true } : {}}
                dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.right?.textTwo) }}
              />}
            </div>
          </motion.div>
        </div>
      </div>
      <Loading active={loading} />
    </>
  );
}

export default CaseDetails;
