import cookingLeftOne from "../../assets/images/team-buildings/cooking/leftOne.webp";
import cookingLeftTwo from "../../assets/images/team-buildings/cooking/leftTwo.webp";
import cookingRightOne from "../../assets/images/team-buildings/cooking/rightOne.webp";
import cookingRighTwo from "../../assets/images/team-buildings/cooking/rightTwo.webp";

import walkingTourLeftOne from "../../assets/images/team-buildings/walking-tour/leftOne.webp";
import walkingTourLeftTwo from "../../assets/images/team-buildings/walking-tour/leftTwo.webp";
import walkingTourRightOne from "../../assets/images/team-buildings/walking-tour/rightOne.webp";
import walkingTourRightTwo from "../../assets/images/team-buildings/walking-tour/rightTwo.webp";

import banqueSeLeftOne from "../../assets/images/team-buildings/banque-se/leftOne.webp";
import banqueSeLeftTwo from "../../assets/images/team-buildings/banque-se/leftTwo.webp";
import banqueSeRightOne from "../../assets/images/team-buildings/banque-se/rightOne.webp";
import banqueSeRightTwo from "../../assets/images/team-buildings/banque-se/rightTwo.webp";

import sociedadePoetasLeftOne from "../../assets/images/team-buildings/poetas/leftOne.webp";

import ballFlowGameLeftOne from "../../assets/images/team-buildings/ballflow/leftOne.webp";
import ballFlowGameRightOne from "../../assets/images/team-buildings/ballflow/rightOne.webp";
import ballFlowGameRightTwo from "../../assets/images/team-buildings/ballflow/rightTwo.webp";

// images recommendation
import ballFlowGame from "../../assets/images/team-buildings/recommendations/ballflow.webp";
import banqueSe from "../../assets/images/team-buildings/recommendations/banque-se.webp";
import cooking from "../../assets/images/team-buildings/recommendations/cooking.webp";
import walkingTour from "../../assets/images/team-buildings/recommendations/walking-tour.webp";


export const buildingListDetails = [
  {
    id: 'cooking-scrum',
    title: "COOKING SCRUM",
    description: "entrada, prato principal <br/> e sobremesa com doses <br/> de team building.",
    left: {
      textOne: "Uma experiência que conjuga gastronomia e agilidade com o objetivo de estimular a cooperação em ambientes de inovação. Afinal, desenvolvimento de conceito, gestão de demandas, testes e apresentação final não acontecem apenas nos escritórios.",
      imageOne: cookingLeftOne,
      textTwo: "É aplicação na prática, em tempo real, com desafios reais<br/> (e, havendo sorte, saborosos também).",
      imageTwo: cookingLeftTwo,
    },
    right: {
      textOne: "Cooking scrum é uma competição colaborativa a altas temperaturas, com desafios individuais e metas coletivas, concebida para reforçar a compreensão dos papéis, cerimônias e artefatos da cultura ágil, bem como a melhoria contínua de processos, produtos e serviços.",
      imageOne: cookingRightOne,
      imageTwo: cookingRighTwo,
      textTwoNegrito: "Está pronto para temperar o trabalho em equipe? <br/><br/> Junte seu time e prepare-se para desafiar a agilidade na cozinha!",
    },
    recommendation: [
      {
        title: "BANQUE-SE",
        description: "Uma vivência sentimental-cognitiva",
        image: banqueSe,
        id: 'banque-se'
      },
      {
        title: "WALKING TOUR",
        description: "A criatividade é cria da atividade",
        image: walkingTour,
        id: 'walking-tour'
      },
    ]
  },
  {
    id: 'walking-tour',
    title: "WALKING TOUR",
    description: "acreditamos que <br/> a criatividade<br/> é cria da atividade.",
    left: {
      textOne: "O walking tour da Mastertech resgata as histórias e transformações da avenida mais famosa da cidade: a Avenida Paulista. No entanto, não nos limitamos apenas a narrar os fatos. Explicamos essa história da mesma forma que se estuda uma empresa que está sempre à frente, adaptando-se rapidamente, ditando tendências e assumindo riscos.",
      imageOne: walkingTourLeftOne,
      textTwo: "Junte-se a nós nessa caminhada. A cidade, seja bela ou feia, verde ou cinzenta, é a melhor sala de aula.",
      imageTwo: walkingTourLeftTwo,
    },
    right: {
      textOne: "Durante nosso tour pelos mais de 2 quilômetros da avenida, apresentamos marcos históricos e os relacionamos com conceitos de design, cultura enxuta, agilidade e negócios. É um passeio que combina turismo, história, educação e diversão.",
      imageOne: walkingTourRightOne,
      imageTwo: walkingTourRightTwo,
      textTwo: "O Walking Tour da Mastertech é um curso dentro do curso natural da vida. É uma via de circulação com muito mais do que duas mãos."
    },
    recommendation: [
      {
        title: "BANQUE-SE",
        description: "Uma vivência sentimental-cognitiva",
        image: banqueSe,
        id: 'banque-se'
      },
      {
        title: "COOKING SCRUM",
        description: "Entrada, prato principal e team buinding.",
        image: cooking,
        id: 'cooking-scrum'
      },
    ]
  },
  {
    id: 'banque-se',
    title: "BANQUE-SE",
    description: "criatividade,<br/> tinta e team building",
    left: {
      textOne: "Na Mastertech, quando a cultura ágil encontra a arte, o resultado é criatividade com método, em um vivência de team building inspirada no grafite de Banksy, artista de rua britânico cuja identidade é desconhecida e que se notabilizou pelas intervenções surpresa.",
      imageOne: banqueSeLeftOne,
      textTwo: "Nesse setup, repassamos conceitos base da metodologia ágil, do lean business e do design thinking, como ciclos de planejamento e produção incremental, gestão de tempo, definição de papeis e delimitação de entregáveis, e ainda adicionamos técnicas de storytelling e pitch para justificar e apresentar o trabalho final.",
      imageTwo: banqueSeLeftTwo,
    },
    right: {
      textOne: "Levamos empresas como A&M, UCB, e IT Forum para uma experiência de skin in the game por 3 horas, com o artista Andy Hope e dois facilitadores da escola, onde, em meio a latas de spray, pano, papelão e plástico, propomos uma atividade lúdica e colaborativa.",
      imageOne: banqueSeRightOne,
      imageTwo: banqueSeRightTwo,
      textTwo: "Banque-se para acessar novos conhecimentos e possibilidades de conexões, para entender como bancar uma oratória clara e coerente, para capturar novos negócios e clientes ao construir uma marca inteligente, que equilibra subversão criativa e pragmatismo."
    },
    recommendation: [
      {
        title: "BALL FLOW GAME",
        description: "o tom é de competição, mas a tônica não.",
        image: ballFlowGame,
        id: 'ball-flow-game'
      },
      {
        title: "WALKING TOUR",
        description: "A criatividade é cria da atividade",
        image: walkingTour,
        id: 'walking-tour'
      },
    ]
  },
  {
    id: 'poetas-remotos',
    title: "SOCIEDADE DOS POETAS REMOTOS",
    description: "um team building <br/> à la clube do livro.",
    left: {
      textOne: "Aqui, se faz necessário um parêntese afetivo para a gente explicar, e você entender, a proposta dessa vivência. A Sociedade dos Poetas Remotos foi uma iniciativa interna da escola para manter nosso time conectado durante a quarentena. <br/><br/><br/> Cada pessoa recebeu um livro-surpresa em casa e, após a leitura, duplas foram formadas para que textos colaborativos fossem escritos, conectando as obras apreciadas por cada um a partir de suas análises pessoais. <br/><br/><br/> Como os compêndios selecionados representam a identidade da Mastertech e seus conteúdos são citados com frequência em nossos cursos, decidimos extrapolar as fronteiras desse nosso espaço de convivência remota, oferecendo para o público essa experiência de troca, de aprendizagem social e de exercício da criatividade. <br/><br/><br/> Devidamente reenquadrado, virou um team building à lá clube do livro, que também ressignifica o aprendizado em equipe enquanto estimula o protagonismo e o auto desenvolvimento. Uma jornada para praticar contexto, reler versos e interpretar entrelinhas.",
    },
    right: {
      imageOne: sociedadePoetasLeftOne,
    },
    recommendation: [
      {
        title: "BALL FLOW GAME",
        description: "o tom é de competição, mas a tônica não.",
        image: ballFlowGame,
        id: 'ball-flow-game'
      },
      {
        title: "COOKING SCRUM",
        description: "Entrada, prato principal e team buinding.",
        image: cooking,
        id: 'cooking-scrum'
      },
    ]
  },
  {
    id: 'ball-flow-game',
    title: "BALL FLOW GAME",
    description: "o tom é de competição, <br/> mas a tônica não.",
    left: {
      textOne: "O desafio, aqui, é aprender a desenvolver estratégias que maximizem o valor das entregas a partir da interação entre times multidisciplinares, baseando-se em conceitos de cultura ágil, gestão visual e de tempo, e filosofia lean.",
      imageOne: ballFlowGameLeftOne,
      textTwo: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum, gestão de tempo, definição de papeis e delimitação de entregáveis, e ainda adicionamos técnicas de storytelling e pitch para justificar e apresentar o trabalho final.",
    },
    right: {
      textOne: "A jornada se consolida no tripé planejamento, execução e retrospectiva, que tenciona, de forma lúdica e pedagógica, o exercício da comunicação e do relacionamento entre pares, apoiada em situações reais de um mercado de trabalho dinâmico e volátil.",
      imageOne: ballFlowGameRightOne,
      imageTwo: ballFlowGameRightTwo,
    },
    recommendation: [
      {
        title: "BANQUE-SE",
        description: "Uma vivência sentimental-cognitiva",
        image: banqueSe,
        id: 'banque-se'
      },
      {
        title: "WALKING TOUR",
        description: "A criatividade é cria da atividade",
        image: walkingTour,
        id: 'walking-tour'
      },
    ]
  },
]

export default buildingListDetails;