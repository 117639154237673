import { motion } from "framer-motion";
import React from 'react';
import { useNavigate } from "react-router-dom";
import arrowButton from '../../../assets/images/banner/arrow-bottom.png';
import groupInfo from '../../../assets/images/banner/group-banner.webp';
import textLogo from '../../../assets/images/banner/text.webp';
import useIsDesktop from "../../../hooks/useIsDesktop";
import Button from '../../common/Button/Button';
import './Banner.scss';

const Banner = () => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const shouldAnimate = isDesktop; // Condicional para animação

  return (
    <div className="banner-container">
      <header className='Banner'>
        {shouldAnimate ? (
          <motion.div
            className="text-container"
            initial={{ x: "-200px", opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: "-20vw", opacity: 0 }}
            transition={{ duration: 0.9, ease: "easeInOut" }}
          >
            <div className="text-container">
              <h1>EDUCAÇÃO <br /> CIRCULAR EM <br /> TODO LUGAR.</h1>
              <img src={textLogo} alt="textLogo" />
            </div>
          </motion.div>
        ) : (
          <div className="text-container">
            <h1>EDUCAÇÃO <br /> CIRCULAR EM <br /> TODO LUGAR.</h1>
            <img src={textLogo} alt="textLogo" />
          </div>
        )}

        {shouldAnimate ? (
          <motion.div
            className="circular-text-container"
            initial={{ y: "200px", opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: "20vw", opacity: 0 }}
            transition={{ duration: 1.3, ease: "easeInOut" }}
          >
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className="circle-svg">
              <defs>
                <path
                  id="circlePath"
                  d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
                />
              </defs>
              <text className="circle-text">
                <textPath href="#circlePath" textAnchor="middle" startOffset="50%">
                  conhecimento em movimento
                </textPath>
              </text>
            </svg>
            <img src={arrowButton} alt="arrowButton" className='arrow' />
          </motion.div>
        ) : (
          <div className="circular-text-container">
            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" className="circle-svg">
              <defs>
                <path
                  id="circlePath"
                  d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
                />
              </defs>
              <text className="circle-text">
                <textPath href="#circlePath" textAnchor="middle" startOffset="50%">
                  conhecimento em movimento
                </textPath>
              </text>
            </svg>
            <img src={arrowButton} alt="arrowButton" className='arrow' />
          </div>
        )}

        {/* Agility Container */}
        <div className="agility-container">
          {shouldAnimate ? (
            <motion.div
              className="text-agility"
              initial={{ opacity: 0, x: window.innerWidth < 1200 ? -200 : -500 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.0, ease: "easeInOut" }}
              viewport={{ once: true }}
            >
              <h3>Tamo aí na <strong>agilidade.</strong></h3>
              <p>Resignificando a educação corporativa há 10 anos.</p>
              <p>
                Aprender é como um ciclo: tudo se conecta, se transforma e volta pra gente de um jeito novo.
                É isso que chamamos de educação circular – um jeito de trocar ideias e experiências onde cada conquista te empurra até a próxima.
              </p>
              <p>Aprender tem que ser orgânico, relevante e pragmático o suficiente pra vida real.</p>
              <Button>saiba mais</Button>
            </motion.div>
          ) : (
            <div className="text-agility">
              <h3>Tamo aí na <strong>agilidade.</strong></h3>
              <p>Resignificando a educação corporativa há 10 anos.</p>
              <p>
                Aprender é como um ciclo: tudo se conecta, se transforma e volta pra gente de um jeito novo.
                É isso que chamamos de educação circular – um jeito de trocar ideias e experiências onde cada conquista te empurra até a próxima.
              </p>
              <p>Aprender tem que ser orgânico, relevante e pragmático o suficiente pra vida real.</p>
              <Button>saiba mais</Button>
            </div>
          )}

          {shouldAnimate ? (
            <motion.div
              className="group-info"
              initial={{ x: window.innerWidth < 1200 ? 200 : 500, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1.0, ease: "easeInOut" }}
              viewport={{ once: true }}
            >
              <img src={groupInfo} alt="groupInfo" />
              <p role="button" className="font" onClick={() => navigate('/team-buildings/walking-tour')}>fonte: walking tour ágil</p>
              <p className="right-flutuant-text">#Punk-seQuemPuder</p>
            </motion.div>
          ) : (
            <div className="group-info">
              <img src={groupInfo} alt="groupInfo" />
              <p role="button" className="font" onClick={() => navigate('/team-buildings/walking-tour')}>fonte: walking tour ágil</p>
              <p className="right-flutuant-text">#Punk-seQuemPuder</p>
            </div>
          )}
        </div>
      </header>
    </div>
  );
};

export default Banner;
