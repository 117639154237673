import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/common/Loading/Loading';
import ContactHome from '../../components/ui/ContactHome/ContactHome';
import { replaceWithLineBreaks } from '../../utils/formating';
import useIsDesktop from '../../hooks/useIsDesktop';
import './BuildingDetails.scss';
import buildingListDetails from './buildingListDetails';

const BuildingDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [contentBuilding, setContentBuilding] = useState(null);
  const [loading, setLoading] = useState(false);
  const isDesktop = useIsDesktop();

  const fetchBuildingDetails = async (id) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      const result = buildingListDetails.find((building) => building.id === id);
      setTimeout(() => {
        if (result) {
          resolve(result);
          setLoading(false);
        } else {
          setLoading(false);
          reject(new Error("Detalhes do building não encontrados."));
        }
      }, 500);
    });
  };

  useEffect(() => {
    const getBuildingDetails = async () => {
      try {
        const result = await fetchBuildingDetails(id);
        setContentBuilding(result);
      } catch (error) {
        console.error(error.message);
        navigate("/team-buildings");
      }
    };

    getBuildingDetails();
  }, [id, navigate]);

  const fadeInLeft = {
    hidden: { opacity: 0, x: -100 },
    visible: {
      opacity: 1,
      x: 0,
      transition: { duration: 1.4, ease: "easeInOut" },
    },
  };

  return (
    <>
      <div className='BuildingDetails'>
        <div className="container-building-details">
          {isDesktop ? (
            <motion.div
              className="header-building-details"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeOut" }}
              viewport={{ once: true }}
            >
              <p className="title">{contentBuilding?.title}</p>
              <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.description) }} />
            </motion.div>
          ) : (
            <div className="header-building-details">
              <p className="title">{contentBuilding?.title}</p>
              <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.description) }} />
            </div>
          )}

          {isDesktop ? (
            <motion.div
              className="content"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1, delay: 0.4 }}
              viewport={{ once: true }}
            >
              <div className="left">
                {contentBuilding?.left?.textOne && (
                  <motion.p
                    variants={fadeInLeft}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                    dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.left?.textOne) }}
                  />
                )}
                {contentBuilding?.left?.imageOne && (
                  <motion.img
                    src={contentBuilding?.left?.imageOne}
                    alt="Imagem 1"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.4 }}
                    viewport={{ once: true }}
                  />
                )}
                {contentBuilding?.left?.textTwoNegrito && <motion.span
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  viewport={{ once: true }}
                >
                  {contentBuilding?.left?.textTwoNegrito}
                </motion.span>}
                {contentBuilding?.left?.textTwo && <motion.p
                  initial={{ opacity: 0 }}
                  className='text-two'
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  viewport={{ once: true }}
                  dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.left?.textTwo) }}
                />}
                {contentBuilding?.left?.imageTwo && (
                  <motion.img
                    src={contentBuilding?.left?.imageTwo}
                    alt="Imagem 2"
                    className='image-two'
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.4 }}
                    viewport={{ once: true }}
                  />
                )}
              </div>

              <div className="right">
                {contentBuilding?.right?.imageOne && (
                  <motion.img
                    src={contentBuilding?.right?.imageOne}
                    alt="Imagem 1"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.4 }}
                    viewport={{ once: true }}
                  />
                )}
                {contentBuilding?.right?.textOne && <motion.p
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  viewport={{ once: true }}
                >
                  {contentBuilding?.right?.textOne}
                </motion.p>}
                {contentBuilding?.right?.imageTwo && (
                  <motion.img
                    src={contentBuilding?.right?.imageTwo}
                    alt="Imagem 2"
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 1, delay: 0.4 }}
                    viewport={{ once: true }}
                  />
                )}
                {contentBuilding?.right?.textTwoNegrito && <motion.span
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  viewport={{ once: true }}
                  dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.right?.textTwoNegrito) }}
                />}

                {contentBuilding?.right?.textTwo && <motion.p
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  viewport={{ once: true }}
                >
                  {contentBuilding?.right?.textTwo}
                </motion.p>}
              </div>
            </motion.div>
          ) : (
            <div className="content">
              <div className="left">
                {contentBuilding?.left?.textOne && (
                  <p dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.left?.textOne) }} />
                )}
                {contentBuilding?.left?.imageOne && (
                  <img
                    src={contentBuilding?.left?.imageOne}
                    alt="Imagem 1"
                  />
                )}
                {contentBuilding?.left?.textTwoNegrito && <span>
                  {contentBuilding?.left?.textTwoNegrito}
                </span>}
                {contentBuilding?.left?.textTwo && <p
                  className='text-two'
                  dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.left?.textTwo) }}
                />}
                {contentBuilding?.left?.imageTwo && (
                  <img
                    src={contentBuilding?.left?.imageTwo}
                    alt="Imagem 2"
                    className='image-two'
                  />
                )}
              </div>

              <div className="right">
                {contentBuilding?.right?.imageOne && (
                  <img
                    src={contentBuilding?.right?.imageOne}
                    alt="Imagem 1"
                  />
                )}
                {contentBuilding?.right?.textOne && <p>
                  {contentBuilding?.right?.textOne}
                </p>}
                {contentBuilding?.right?.imageTwo && (
                  <img
                    src={contentBuilding?.right?.imageTwo}
                    alt="Imagem 2"
                  />
                )}
                {contentBuilding?.right?.textTwoNegrito && <span
                  dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentBuilding?.right?.textTwoNegrito) }}
                />}
                {contentBuilding?.right?.textTwo && <p>
                  {contentBuilding?.right?.textTwo}
                </p>}
              </div>
            </div>
          )}
        </div>
      </div>

      {!loading && <ContactHome
        text={`Quer levar o ${contentBuilding?.title} para a <strong>sua empresa?</strong>`}
      />}

      {isDesktop ? (
        <motion.div
          className="you-like-section"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.4 }}
          viewport={{ once: true }}
        >
          <div className="container-you-like">
            <p className="title-like">Você também pode gostar</p>
            <div className="cards">
              {contentBuilding?.recommendation?.map((item, index) => (
                <motion.div
                  className="card"
                  key={index}
                  initial={{ opacity: 0 }}
                  whileInView={{ opacity: 1 }}
                  transition={{ duration: 1, delay: 0.4 }}
                  viewport={{ once: true }}
                  onClick={() => {
                    navigate(`/team-buildings/${item.id}`)
                  }}
                >
                  <img src={item.image} alt="Imagem de um mural de prato" />
                  <div className="content-card">
                    <p className="title">{item.title}</p>
                    <p className="description">{item.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </motion.div>
      ) : (
        <div className="you-like-section">
          <div className="container-you-like">
            <p className="title-like">Você também pode gostar</p>
            <div className="cards">
              {contentBuilding?.recommendation?.map((item, index) => (
                <div
                  className="card"
                  key={index}
                  onClick={() => {
                    navigate(`/team-buildings/${item.id}`)
                  }}
                >
                  <img src={item.image} alt="Imagem de um mural de prato" />
                  <div className="content-card">
                    <p className="title">{item.title}</p>
                    <p className="description">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      <Loading active={loading} />
    </>
  );
}

export default BuildingDetails;
