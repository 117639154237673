import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from '../../components/common/Loading/Loading';
import ContactHome from '../../components/ui/ContactHome/ContactHome';
import useIsDesktop from '../../hooks/useIsDesktop';
import { replaceWithLineBreaks } from '../../utils/formating';
import './TrainingDetails.scss';
import { traininglist } from './trainingList';

const TrainingDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [contentTraining, setContentTraining] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nome: "",
    empresa: "",
    celular: "",
    email: "",
    mensagem: "",
  });
  const [errors, setErrors] = useState({});
  const isDesktop = useIsDesktop();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.nome.trim()) newErrors.nome = "Nome completo é obrigatório.";
    if (!formData.empresa.trim()) newErrors.empresa = "Empresa é obrigatória.";
    if (!formData.celular.trim()) newErrors.celular = "Celular é obrigatório.";
    if (!formData.email.trim()) {
      newErrors.email = "E-mail é obrigatório.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "E-mail inválido.";
    }

    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      alert("Formulário enviado com sucesso!");
      setFormData({
        nome: "",
        empresa: "",
        celular: "",
        email: "",
      });
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };

  const fetchTrainingDetails = async (id) => {
    setLoading(true);
    return new Promise((resolve, reject) => {
      const result = traininglist.find((training) => training.id === id);
      setTimeout(() => {
        if (result) {
          resolve(result);
          setLoading(false);
        } else {
          setLoading(false);
          reject(new Error("Detalhes do training não encontrados."));
        }
      }, 500);
    });
  };

  useEffect(() => {
    const getTrainingDetails = async () => {
      try {
        const result = await fetchTrainingDetails(id);
        setContentTraining(result);
      } catch (error) {
        console.error(error.message);
        navigate("/trilhas-formativas");
      }
    };

    getTrainingDetails();
  }, [id, navigate]);

  return (
    <>
      <div className='TrainingDetails'>
        <div className="container-training-details">
          <motion.div
            className="header-details"
            initial={isDesktop ? { opacity: 0, y: 50 } : {}}
            whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1, ease: "easeOut" }}
            viewport={{ once: true }}
          >
            <div className="content">
              <h1 dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentTraining?.title) }} />
              <p className='subtitle' dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentTraining?.subtitle) }} />
              <p className='description'>{contentTraining?.description}</p>
            </div>
            <div className="header-img">
              {contentTraining?.image && <img src={contentTraining?.image} alt={contentTraining?.title} />}
            </div>
          </motion.div>

          <div className="learning-section">
            <h2 className="learning-section__title">O QUE VOCÊ VAI APRENDER</h2>
            <div className="learning-section__content">
              {contentTraining?.fundamentals.map((fundamental, index) => (
                <motion.div
                  key={index}
                  className={`learning-section__item ${index % 2 === 0 ? 'learning-section__item--left' : 'learning-section__item--right'}`}
                  initial={isDesktop ? { opacity: 0, x: index % 2 === 0 ? -50 : 50 } : {}}
                  whileInView={isDesktop ? { opacity: 1, x: 0 } : {}}
                  transition={{ duration: 0.8, delay: 0.4 }}
                  viewport={{ once: true }}
                >
                  <div className="learning-section__item-learning-box">
                    <h3 className="learning-section__item-title">
                      {fundamental.title || "Fundamentos"} <br /> <span>{fundamental.subtitle || "do ChatGPT"}</span>
                    </h3>
                    <p className="learning-section__item-description">
                      {fundamental.describe}
                    </p>
                  </div>
                  <div className="dot" />
                </motion.div>
              ))}

              {/* Texto flutuante */}
              <motion.p
                className="text-flutuant"
                initial={isDesktop ? { x: "-200px", opacity: 0 } : {}}
                animate={isDesktop ? { x: 0, opacity: 1 } : {}}
                exit={isDesktop ? { x: "-20vw", opacity: 0 } : {}}
                transition={{ duration: 1.5, ease: "easeInOut" }}
                viewport={{ once: true }}
              >
                gen IA
              </motion.p>
            </div>
          </div>

          <div className="duration-section">
            <div className="duration">
              <motion.p
                className="duration-title"
                initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, delay: 0.4 }}
                viewport={{ once: true }}
              >
                DURAÇÃO
              </motion.p>
              <motion.p
                className="duration-content"
                initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, delay: 0.4 }}
                viewport={{ once: true }}
                dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentTraining?.duration) }}
              />
            </div>

            <div className="duration">
              <motion.p
                className="duration-title"
                initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, delay: 0.4 }}
                viewport={{ once: true }}
              >
                EXPERIÊNCIA
              </motion.p>
              <motion.p
                className="duration-content"
                initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, delay: 0.4 }}
                viewport={{ once: true }}
                dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentTraining?.experience) }}
              />
            </div>

            <div className="duration">
              <motion.p
                className="duration-title"
                initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, delay: 0.4 }}
                viewport={{ once: true }}
              >
                PARTICIPANTES
              </motion.p>
              <motion.p
                className="duration-content"
                initial={isDesktop ? { opacity: 0, y: 50 } : {}}
                whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 1, delay: 0.4 }}
                viewport={{ once: true }}
                dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(contentTraining?.participants) }}
              />
            </div>
          </div>
        </div>
      </div>

      <ContactHome witdh text={`Quer levar o <strong>${contentTraining?.minTitle}</strong> para sua empresa? <br/>`} />
      <Loading active={loading} />
    </>
  );
}

export default TrainingDetails;
