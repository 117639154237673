import { motion } from "framer-motion";
import React from "react";
import arrowButton from "../../assets/images/banner/arrow-bottom.png";
import ballFlowGame from "../../assets/images/buildinglist/ball-flow.webp";
import banqueSe from "../../assets/images/buildinglist/banque-se.webp";
import cooking from "../../assets/images/buildinglist/cooking.webp";
import sociedadePoetas from "../../assets/images/buildinglist/poetas-remotos.webp";
import textImage from "../../assets/images/buildinglist/text.png";
import walkingTour from "../../assets/images/buildinglist/walking.webp";

import { useNavigate } from "react-router-dom";
import { replaceWithLineBreaks } from "../../utils/formating";
import "./BuildingList.scss";
import useIsDesktop from "../../hooks/useIsDesktop";

const BuildingList = () => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const buildinglist = [
    {
      id: 'cooking-scrum',
      title: "Cooking scrum",
      description: "Uma cozinha, um menu completo, doses de team building e pitadas de cultura ágil.",
      image: cooking,
    },
    {
      id: 'walking-tour',
      title: "Walking Tour",
      description: "Agilidade na prática e no concreto, movimentando-se pelas ruas para criar novas ideias.",
      image: walkingTour,
    },
    {
      id: 'banque-se',
      title: "Banque-se",
      description: "Pele em jogo, spray e muito grafite para a construção de um time inteligente.",
      image: banqueSe,
    },
    {
      id: 'poetas-remotos',
      title: "Sociedade dos poetas remotos",
      description: "Um team building à lá clube do livro que ressignifica o aprendizado <br/> em equipe enquanto estimula o protagonismo.",
      image: sociedadePoetas,
    },
    {
      id: 'ball-flow-game',
      title: "Ball flow game",
      description: "Team building e liderança compartilhada amparados na cultura ágil e filosofia lean.",
      image: ballFlowGame,
    },
  ];

  return (
    <div className="BuildingList">
      <div className="container-buildinglist">
        {isDesktop ? (
          <motion.div
            className="header-buildinglist"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2 }}
          >
            <h1>Team buildings Mastertech</h1>
            <h2>
              deixe seu ego na porta.
              <img src={textImage} alt="Tamo aí na agilidade" />
            </h2>
            <div className="content">
              <div className="info-text">
                <p>
                  Nossos team buildings jogam os times direto na ação, com atividades que provocam, sacodem e fazem todo mundo sair da zona de conforto. É sobre se conectar de verdade, resolver tretas criativas e transformar ideias em coisa grande. Menos blá-blá-blá, mais mão na massa. Se é pra construir time, que seja um time f*da!
                </p>
              </div>
              <motion.div
                className="circular-text-container"
                initial={{ y: "200px", opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: "20vw", opacity: 0 }}
                transition={{ duration: 2, ease: "easeInOut" }}
              >
                <svg
                  viewBox="0 0 100 100"
                  xmlns="http://www.w3.org/2000/svg"
                  className="circle-svg"
                >
                  <defs>
                    <path
                      id="circlePath"
                      d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
                    />
                  </defs>
                  <text className="circle-text">
                    <textPath
                      href="#circlePath"
                      textAnchor="middle"
                      startOffset="50%"
                    >
                      conhecimento em movimento
                    </textPath>
                  </text>
                </svg>
                <img src={arrowButton} alt="arrowButton" className="arrow" />
              </motion.div>
            </div>
          </motion.div>
        ) : (
          <div className="header-buildinglist">
            <h1>Team buildings Mastertech</h1>
            <h2>
              deixe seu ego na porta.
              <img src={textImage} alt="Tamo aí na agilidade" />
            </h2>
            <div className="content">
              <div className="info-text">
                <p>
                  Nossos team buildings jogam os times direto na ação, com atividades que provocam, sacodem e fazem todo mundo sair da zona de conforto. É sobre se conectar de verdade, resolver tretas criativas e transformar ideias em coisa grande. Menos blá-blá-blá, mais mão na massa. Se é pra construir time, que seja um time f*da!
                </p>
              </div>
            </div>
          </div>
        )}
        {isDesktop ? (
          <motion.ul
            className="list-buildinglist"
            initial="hidden"
            animate="visible"
            variants={{
              hidden: { opacity: 0 },
              visible: {
                opacity: 1,
                transition: {
                  staggerChildren: 0.5,
                },
              },
            }}
          >
            {buildinglist.map((trail, index) => (
              <motion.li
                key={trail.id}
                className={`trail ${index === 0 ? "trail-first" : ""}`}
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, margin: "-100px" }}
                transition={{ duration: 2 }}
              >
                <div className="infos-buildinglist">
                  <div>
                    <p className="title">{trail.title}</p>
                    <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(trail.description) }} />
                  </div>
                  <button onClick={() => navigate(`/team-buildings/${trail.id}`)}>
                    saiba mais
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25px"
                      height="25px"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M18.5 12.214a1 1 0 0 0-1-1H5a1 1 0 1 0 0 2h12.5a1 1 0 0 0 1-1"
                        clipRule="evenodd"
                      />
                      <path
                        fill="#fff"
                        fillRule="evenodd"
                        d="M20 12.214a1 1 0 0 0-.293-.707l-4.5-4.5a1 1 0 1 0-1.414 1.414l3.793 3.793l-3.793 3.793a1 1 0 0 0 1.414 1.415l4.5-4.5a1 1 0 0 0 .293-.708"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div className="container-img">
                  <img src={trail.image} alt={trail.title} />
                </div>
              </motion.li>
            ))}
          </motion.ul>
        ) : (
          <ul className="list-buildinglist">
            {buildinglist.map((trail, index) => (
              <li key={trail.id} className={`trail ${index === 0 ? "trail-first" : ""}`}>
                <div className="infos-buildinglist">
                  <div>
                    <p className="title">{trail.title}</p>
                    <p className="description" dangerouslySetInnerHTML={{ __html: replaceWithLineBreaks(trail.description) }} />
                  </div>
                  <button onClick={() => navigate(`/team-buildings/${trail.id}`)}>
                    saiba mais
                  </button>
                </div>
                <div className="container-img">
                  <img src={trail.image} alt={trail.title} />
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default BuildingList;
