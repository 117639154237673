import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import cardOne from '../../assets/images/mastertechwho/card-one.webp';
import cardThree from '../../assets/images/mastertechwho/card-three.webp';
import cardTwo from '../../assets/images/mastertechwho/card-two.webp';
import leftTirinha from '../../assets/images/mastertechwho/left-tirinha.webp';
import rightPunk from '../../assets/images/mastertechwho/right-punk.webp';
import rightTirinha from '../../assets/images/mastertechwho/right-tirinha.webp';
import textImage from '../../assets/images/mastertechwho/text.png';
import './MastertechWho.scss';
import useIsDesktop from '../../hooks/useIsDesktop';

const fadeIn = {
  hidden: { opacity: 0, y: 20 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
};

const staggerContainer = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { staggerChildren: 0.3 } },
};

const MastertechWho = () => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  return (
    <>
      <motion.div className="MastertechWho">
        {isDesktop ? (
          <motion.div
            className="container-mastertec-who"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.2 }}
            variants={staggerContainer}
          >
            <motion.div className="left" variants={fadeIn}>
              <div className="container-title">
                <h1>MASTERTECH WHO?</h1>
                <h2>
                  educação circular <br /> em <strong>todo lugar.</strong>
                  <img src={textImage} alt="Tamo aí na agilidade" />
                </h2>
              </div>
              <motion.div className="content" variants={fadeIn}>
                <p>
                  A gente aprende mais, melhor e sempre quando a gente está presente no mundo e faz dele a nossa sala de aula. E aqui na Mastertech, chamamos isso de Educação Circular. A gente acredita que todo momento pode ser uma oportunidade de aprendizado — desde assistir um filme até resolver um problema no trabalho ou bater um papo aleatório na fila do mercado. O segredo está em como a gente processa essas experiências, como traduzimos o que acontece ao nosso redor em novos insights e oportunidades.
                </p>
                <motion.img src={leftTirinha} alt="leftTirinha" variants={fadeIn} />
                <p>
                  E estamos bem. Mais de 150 clientes corporativos em quase 10 anos. Referência em ensino de programação e transformação digital. Estamos em constante evolução, construindo novos caminhos e impactando pessoas. Se o mundo inteiro pode ser uma sala de aula, vem com a gente aprender com ele.
                </p>
              </motion.div>
            </motion.div>
            <motion.div className="right" variants={fadeIn}>
              <motion.div className="content" variants={fadeIn}>
                <motion.img src={rightTirinha} alt="rightTirinha" />
                <p>
                  A Educação Circular é sobre repensar, reciclar e reutilizar tudo que cruza o nosso caminho. Cada situação, cada erro, cada descoberta pode virar conhecimento de verdade. Não existe um começo, meio e fim fixo: o aprendizado é contínuo e dinâmico, igual à vida. E foi assim que criamos programas para professores, lançamos projetos sociais, fortalecemos o B2B e o nosso relacionamento com empresas. Criamos um laboratório de práticas ágeis e de pesquisas, porque acreditamos que a educação acontece em todos os lugares e para todas as pessoas. Público, privado, empresa, indivíduo e família — todos têm um papel essencial no aprendizado.
                </p>
                <motion.div className="left-image" variants={fadeIn}>
                  <motion.img src={rightPunk} alt="rightPunk" />
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
        ) : (
          <div className="container-mastertec-who">
            <div className="left">
              <div className="container-title">
                <h1>MASTERTECH WHO?</h1>
                <h2>
                  educação circular <br /> em <strong>todo lugar.</strong>
                  <img src={textImage} alt="Tamo aí na agilidade" />
                </h2>
              </div>
              <div className="content">
                <p>
                  A gente aprende mais, melhor e sempre quando a gente está presente no mundo e faz dele a nossa sala de aula. E aqui na Mastertech, chamamos isso de Educação Circular. A gente acredita que todo momento pode ser uma oportunidade de aprendizado — desde assistir um filme até resolver um problema no trabalho ou bater um papo aleatório na fila do mercado. O segredo está em como a gente processa essas experiências, como traduzimos o que acontece ao nosso redor em novos insights e oportunidades.
                </p>
                <img src={leftTirinha} alt="leftTirinha" />
                <p>
                  E estamos bem. Mais de 150 clientes corporativos em quase 10 anos. Referência em ensino de programação e transformação digital. Estamos em constante evolução, construindo novos caminhos e impactando pessoas. Se o mundo inteiro pode ser uma sala de aula, vem com a gente aprender com ele.
                </p>
              </div>
            </div>
            <div className="right">
              <div className="content">
                <img src={rightTirinha} alt="rightTirinha" />
                <p>
                  A Educação Circular é sobre repensar, reciclar e reutilizar tudo que cruza o nosso caminho. Cada situação, cada erro, cada descoberta pode virar conhecimento de verdade. Não existe um começo, meio e fim fixo: o aprendizado é contínuo e dinâmico, igual à vida. E foi assim que criamos programas para professores, lançamos projetos sociais, fortalecemos o B2B e o nosso relacionamento com empresas. Criamos um laboratório de práticas ágeis e de pesquisas, porque acreditamos que a educação acontece em todos os lugares e para todas as pessoas. Público, privado, empresa, indivíduo e família — todos têm um papel essencial no aprendizado.
                </p>
                <div className="left-image">
                  <img src={rightPunk} alt="rightPunk" />
                </div>
              </div>
            </div>
          </div>
        )}
      </motion.div>

      {isDesktop && (
        <motion.div
          className="HouseKids"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={staggerContainer}
        >
          <div className="container-housekids">
            <motion.h4 variants={fadeIn}>CRIAS DA CASA</motion.h4>
            <motion.div className="content" variants={staggerContainer}>
              <motion.div className="card-housekids" variants={fadeIn} onClick={() => window.open('https://somas.org.br/', '_blank')}>
                <div className="header-card">
                  <p className="title">SOMAS</p>
                  <p className="subtitle">A nossa ONG</p>
                </div>
                <motion.img src={cardOne} alt="cardOne" variants={fadeIn} />
                <button>
                  SAIBA MAIS <span className="ph--arrow-right-bold" />
                </button>
              </motion.div>
              <motion.div className="card-housekids" variants={fadeIn} onClick={() => window.open('https://hotmart.com/pt-br/marketplace/produtos/hey-ia-reflexoes-sobre-os-avancos-das-inteligencias-artificiais-volume-1/N94299296Y', '_blank')}>
                <div className="header-card">
                  <p className="title">HEY IA</p>
                  <p className="subtitle">O nosso livro</p>
                </div>
                <motion.img src={cardTwo} alt="cardTwo" variants={fadeIn} />
                <button>
                  SAIBA MAIS <span className="ph--arrow-right-bold" />
                </button>
              </motion.div>
              <motion.div className="card-housekids" variants={fadeIn} onClick={() => navigate('/case/pc-circular')}>
                <div className="header-card">
                  <p className="title">Computador circular</p>
                  <p className="subtitle">O nosso jeito de fazer educação</p>
                </div>
                <motion.img src={cardThree} alt="cardThree" variants={fadeIn} />
                <button>
                  SAIBA MAIS <span className="ph--arrow-right-bold" />
                </button>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      )}

      {!isDesktop && (
        <div className="HouseKids">
          <div className="container-housekids">
            <h4>CRIAS DA CASA</h4>
            <div className="content">
              <div className="card-housekids" onClick={() => window.open('https://somas.org.br/', '_blank')}>
                <div className="header-card">
                  <p className="title">SOMAS</p>
                  <p className="subtitle">A nossa ONG</p>
                </div>
                <img src={cardOne} alt="cardOne" />
                <button>
                  SAIBA MAIS <span className="ph--arrow-right-bold" />
                </button>
              </div>
              <div className="card-housekids" onClick={() => window.open('https://hotmart.com/pt-br/marketplace/produtos/hey-ia-reflexoes-sobre-os-avancos-das-inteligencias-artificiais-volume-1/N94299296Y', '_blank')}>
                <div className="header-card">
                  <p className="title">HEY IA</p>
                  <p className="subtitle">O nosso livro</p>
                </div>
                <img src={cardTwo} alt="cardTwo" />
                <button>
                  SAIBA MAIS <span className="ph--arrow-right-bold" />
                </button>
              </div>
              <div className="card-housekids" onClick={() => navigate('/case/pc-circular')}>
                <div className="header-card">
                  <p className="title">Computador circular</p>
                  <p className="subtitle">O nosso jeito de fazer educação</p>
                </div>
                <img src={cardThree} alt="cardThree" />
                <button>
                  SAIBA MAIS <span className="ph--arrow-right-bold" />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MastertechWho;
