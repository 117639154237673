import React from 'react';
import { isMobile } from '../../../utils/responsiveness';
import './ImageWithBadge.scss';

const ImageWithBadge = ({
  src,
  alt = 'Image',
  badgeText,
  captionText,
  size,
  width,
  height,
  titleText,
  small,
  mdaily,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={`ImageWithBadge ${mdaily ? 'mdaily' : ''} mobile ${isMobile() ? 'mobile' : ''}`} style={{ size, width, height }}>
      <img
        lazy={true}
        src={src}
        alt={alt}
        className="image"
        width={size}
        height={size}
      />
      {!mdaily && <div className={`caption ${small ? 'small' : ''}`}>
        <p className='title'>{titleText}</p>
        <p>{captionText}</p>
      </div>}
      {mdaily && <div className={`mdaily-caption`}>
        <p>{captionText}</p>
      </div>}
    </div>
  );
};

export default ImageWithBadge;
