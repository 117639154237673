import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavigationLink from '../../common/NavigationLink/NavigationLink';
import './Navbar.scss';

const Navbar = ({ sendToBack }) => {
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const switchMenuMobileisOpen = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  const handleSubmenuToggle = (submenu) => {
    setActiveSubmenu(prevState => prevState === submenu ? null : submenu);
  };


  const submenuItems = {
    servicos: [
      { href: "/trilha/gen-ai", label: "INTELIGÊNCIA ARTIFICIAL" },
      { href: "/trilha/up-to-data", label: "CULTURA DE DADOS" },
      { href: "/trilha/mindset-agil", label: "MINDSET ÁGIL" },
      { href: "/trilha/ppnp", label: "PPNP" },
      { href: "/trilha/transformacao-digital", label: "TRANSFORMAÇÃO DIGITAL" },
    ],
    teamBuilding: [
      { href: "/team-buildings/cooking-scrum", label: "COOKING SCRUM" },
      { href: "/team-buildings/walking-tour", label: "WALKING TOUR" },
      { href: "/team-buildings/banque-se", label: "BANQUE-SE" },
      { href: "/team-buildings/poetas-remotos", label: "SOCIEDADE DOS POETAS REMOTOS" },
      { href: "/team-buildings/ball-flow-game", label: "BALL FLOW GAME" },
    ],
    outrosServicos: [
      { href: "/academy", label: "Academy" },
      { href: "/coworkings", label: "Coworkings" },
      { href: "/portal-de-vagas", label: "Portal de Vagas" },
    ],
  };

  return (
    <div className="navbar-container">
      <nav className={`Navbar ${sendToBack ? "back" : ""} `}>
        <div className="header-navbar">
          <a href={window.location.origin}>
            <h1>mastertech</h1>
          </a>
          <button onClick={() => switchMenuMobileisOpen()}>
            {!isSubmenuOpen && <span class="material-symbols--menu-rounded"></span>}
            {isSubmenuOpen && <span class="line-md--menu-to-close-alt-transition"></span>}
          </button>
        </div>
        <div className='container-buttons'>
          <NavigationLink href='/mastertech-who' text='Home' active={pathname === '/mastertech-who'}>MASTERTECH WHO?</NavigationLink>
          <div
            className="navigation"
            onMouseEnter={() => setIsMenuOpen(true)}
            onMouseLeave={() => {
              setIsMenuOpen(false);
              setIsSubmenuOpen(false);
            }}
          >
            <NavigationLink
              className="menu-link"
              highlight
              isOpen={isMenuOpen}
              active={false}
            >
              NA SUA EMPRESA
            </NavigationLink>

            <div className={`dropdown-menu ${isMenuOpen ? 'visible' : ''}`}>
              <div
                className="menu-item"
                onMouseEnter={(e) => e.currentTarget.querySelector('.submenu').classList.add('visible')}
                onMouseLeave={(e) => e.currentTarget.querySelector('.submenu').classList.remove('visible')}
              >
                <a href="/trilhas-formativas" className="submenu-link">TRILHAS FORMATIVAS +</a>
                <div className="submenu">
                  <a href="/trilha/gen-ai">INTELIGÊNCIA ARTIFICIAL</a>
                  <a href="/trilha/up-to-data">CULTURA DE DADOS</a>
                  <a href="/trilha/mindset-agil">MINDSET ÁGIL</a>
                  <a href="/trilha/ppnp">PPNP</a>
                  <a href="/trilha/transformacao-digital">TRANSFORMAÇÃO DIGITAL</a>
                </div>
              </div>

              <div
                className="menu-item"
                onMouseEnter={(e) => e.currentTarget.querySelector('.submenu').classList.add('visible')}
                onMouseLeave={(e) => e.currentTarget.querySelector('.submenu').classList.remove('visible')}
              >
                <a href="/team-buildings" className="submenu-link">TEAM BUILDING +</a>
                <div className="submenu">
                  <a href="/team-buildings/cooking-scrum">COOKING SCRUM</a>
                  <a href="/team-buildings/walking-tour">WALKING TOUR</a>
                  <a href="/team-buildings/banque-se">BANQUE-SE</a>
                  <a href="/team-buildings/poetas-remotos">SOCIEDADE DOS POETAS REMOTOS</a>
                  <a href="/team-buildings/ball-flow-game">BALL FLOW GAME</a>
                </div>
              </div>
              <a href="/consultoria">CONSULTORIA</a>
              <a href="/mtalks">M/TALKS</a>
            </div>
          </div>
          <NavigationLink
            href="/cases"
            className="menu-link"
            highlight
            active={pathname.includes('/cases')}
          >
            CASES
          </NavigationLink>

          <NavigationLink href='/contato' active={pathname.includes('/contato')}>CONTATO</NavigationLink>
        </div>
      </nav>

      <motion.nav
        className={`nav-menu ${isSubmenuOpen ? 'open' : ''}`}
        initial={{ y: '-100%' }}
        animate={{ y: 0 }}
        exit={{ y: '-100%' }}
        transition={{ type: 'spring', stiffness: 50 }}
      >
        <ul className="menu">
          <li className="menu-item">
            <a href="/mastertech-who">MASTERTECH WHO?</a>
          </li>
          <li className="menu-item">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleSubmenuToggle('servicos');
              }}
            >
              TRILHAS FORMATIVAS +
            </a>
            <motion.ul
              className={`submenu ${activeSubmenu === 'servicos' ? 'open' : ''}`}
              initial={{ height: 0 }}
              animate={{
                height: activeSubmenu === 'servicos' ? 'auto' : 0,
              }}
              exit={{
                height: 0,
              }}
              transition={{ type: 'spring', stiffness: 100, damping: 25 }}
            >
              {submenuItems.servicos.map((item, index) => (
                <motion.li
                  key={item.href}
                  initial={{ y: -10 }}
                  animate={{
                    y: activeSubmenu === 'servicos' ? 0 : -10,
                  }}
                  transition={{
                    delay: index * 0.1,
                    type: 'spring',
                    stiffness: 100,
                    damping: 25,
                  }}
                >
                  <a href={item.href}>{item.label}</a>
                </motion.li>
              ))}
            </motion.ul>
          </li>
          <li className="menu-item">
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleSubmenuToggle('teamBuilding');
              }}
            >
              TEAM BUILDING +
            </a>
            <motion.ul
              className={`submenu ${activeSubmenu === 'teamBuilding' ? 'open' : ''}`}
              initial={{ height: 0 }}
              animate={{
                height: activeSubmenu === 'teamBuilding' ? 'auto' : 0,
              }}
              exit={{
                height: 0,
              }}
              transition={{ type: 'spring', stiffness: 100, damping: 25 }}
            >
              {submenuItems.teamBuilding.map((item, index) => (
                <motion.li
                  key={item.href}
                  initial={{ y: -10 }}
                  animate={{
                    y: activeSubmenu === 'teamBuilding' ? 0 : -10,
                  }}
                  transition={{
                    delay: index * 0.1,
                    type: 'spring',
                    stiffness: 100,
                    damping: 25,
                  }}
                >
                  <a href={item.href}>{item.label}</a>
                </motion.li>
              ))}
            </motion.ul>
          </li>
          <li className="menu-item">
            <a href="/contato">Contato</a>
          </li>
        </ul>
      </motion.nav>
    </div>
  )
}

export default Navbar;
