import { motion } from "framer-motion";
import React from "react";
import arrowButton from "../../assets/images/banner/arrow-bottom.png";
import genai from "../../assets/images/trainingtrails/genai.webp";
import mindset from "../../assets/images/trainingtrails/mindset.webp";
import ppnp from "../../assets/images/trainingtrails/ppnp.webp";
import transform from "../../assets/images/trainingtrails/transform.webp";
import upToData from "../../assets/images/trainingtrails/up-to-data.webp";

import { useNavigate } from "react-router-dom";
import "./TrainingTrails.scss";
import useIsDesktop from "../../hooks/useIsDesktop";

const TrainingTrails = () => {
  const isDesktop = useIsDesktop();
  const navigate = useNavigate();
  const trailsData = [
    {
      id: 'gen-ai',
      title: "Gen AI",
      description: "Inteligência artificial como aliada na geração de insights.",
      image: genai,
    },
    {
      id: 'up-to-data',
      title: "Up to data",
      description: "Do pensamento data-driven à tomada de decisões assertivas.",
      image: upToData,
    },
    {
      id: 'mindset-agil',
      title: "Mindset ágil",
      description: "Das estruturas convencionais de gestão à metodologia ágil.",
      image: mindset,
    },
    {
      id: 'ppnp',
      title: "Programação para não programadores",
      description: "Uma introdução à programação para grupos não técnicos.",
      image: ppnp,
    },
    {
      id: 'transformacao-digital',
      title: "Transformação digital",
      description: "Compreensão do contexto, relações sociais e pensamento crítico.",
      image: transform,
    },
  ];

  return (
    <div className="TrainingTrails">
      <div className="container-training-trails">
        {/* Condição de animação */}
        <motion.div
          className="header-training"
          initial={isDesktop ? { opacity: 0, y: -50 } : {}}
          animate={isDesktop ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 2 }}
        >
          <h1>Trilhas formativas da Mastertech</h1>
          <h2>mais giz, menos xis.</h2>

          <div className="content">
            <div className="info-text">
              <p>Nenhum curso nosso é CTRL C + CTRL V.</p>
              <p>
                Eles mergulham no mundo real usando exemplos e contextos do seu
                mercado pra criar algo só seu e do time. Tecnologia,
                criatividade, agilidade: tudo junto, misturado e direto ao
                ponto.
              </p>
            </div>
            <motion.div
              className="circular-text-container"
              initial={isDesktop ? { y: "200px", opacity: 0 } : {}}
              animate={isDesktop ? { y: 0, opacity: 1 } : {}}
              exit={isDesktop ? { y: "20vw", opacity: 0 } : {}}
              transition={{ duration: 2, ease: "easeInOut" }}
            >
              <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                className="circle-svg"
              >
                <defs>
                  <path
                    id="circlePath"
                    d="M 50, 50 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
                  />
                </defs>
                <text className="circle-text">
                  <textPath
                    href="#circlePath"
                    textAnchor="middle"
                    startOffset="50%"
                  >
                    conhecimento em movimento
                  </textPath>
                </text>
              </svg>
              <img src={arrowButton} alt="arrowButton" className="arrow" />
            </motion.div>
          </div>
        </motion.div>

        {/* Lista de trilhas */}
        <motion.ul
          className="list-trails"
          initial={isDesktop ? "hidden" : {}}
          animate={isDesktop ? "visible" : {}}
          variants={{
            hidden: { opacity: 0 },
            visible: {
              opacity: 1,
              transition: {
                staggerChildren: 0.5,
              },
            },
          }}
        >
          {trailsData.map((trail, index) => (
            <motion.li
              key={trail.id}
              className={`trail ${index === 0 ? "trail-first" : ""}`}
              initial={isDesktop ? { opacity: 0, y: 50 } : {}}
              whileInView={isDesktop ? { opacity: 1, y: 0 } : {}}
              viewport={{ once: true, margin: "-100px" }}
              transition={{ duration: 2 }}
            >
              <div className="infos-trail">
                <div>
                  <p className="title">{trail.title}</p>
                  <p className="description">{trail.description}</p>
                </div>
                <button onClick={() => navigate(`/trilha/${trail.id}`)}>
                  saiba mais
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25px"
                    height="25px"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#fff"
                      fillRule="evenodd"
                      d="M18.5 12.214a1 1 0 0 0-1-1H5a1 1 0 1 0 0 2h12.5a1 1 0 0 0 1-1"
                      clipRule="evenodd"
                    />
                    <path
                      fill="#fff"
                      fillRule="evenodd"
                      d="M20 12.214a1 1 0 0 0-.293-.707l-4.5-4.5a1 1 0 1 0-1.414 1.414l3.793 3.793l-3.793 3.793a1 1 0 0 0 1.414 1.415l4.5-4.5a1 1 0 0 0 .293-.708"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>
              <div className="container-img">
                <img src={trail.image} alt={trail.title} />
              </div>
            </motion.li>
          ))}
        </motion.ul>
      </div>
    </div>
  );
};

export default TrainingTrails;
