export const talksData = [
  {
    id: 1,
    title: "talks #1",
    subtitle: "Diversidade e inclusão na prática",
    description: "Desvendamos táticas e pavimentamos caminhos para <br/> implementar esses princípios na cultura organizacional e<br/> obter resultados tangíveis.",
    dot: 'right'
  },
  {
    id: 2,
    title: "talks #2",
    subtitle: "A neuroplasticidade do “aprender a aprender”",
    description: "Ensinamos como utilizar a maleabilidade do cérebro<br/>  humano para melhorar processos de aprendizado e o<br/>  desenvolvimento de habilidades."
  },
  {
    id: 3,
    title: "talks #3",
    subtitle: "As possibilidades e oportunidades do futuro artificial",
    description: "O avanço da IAGen traz incertezas, mas também traz<br/> oportunidades. Estagnar ou prosperar depende do quanto<br/> nos abrimos às possibilidades que essa tecnologia oferece.",
    dot: 'left'
  },
  {
    id: 4,
    title: "talks #4",
    subtitle: "O uso do ChatGPT no desenvolvimento profissional",
    description: "A IAGen, que já está reconfigurando mercados e carreiras,<br/> também pode ser incorporada nos treinamentos <br/>corporativos para enriquecer o processo de aprendizado.<br/> Nós ensinamos como."
  },

  {
    id: 5,
    title: "talks #5",
    subtitle: "A sociedade hiperconectada vista e analisada sobre o viés da cultura de dados",
    description: "Focamos no ser humano para transformar a maneira como<br/> os dados são vistos e utilizados na lógica corporativa.",
    dot: 'right'
  },
  {
    id: 6,
    title: "talks #6",
    subtitle: "O \"resgate do eu\" e o protagonismo não violento<br/> em ambientes colaborativos, diversos e inclusivos",
    description: "No contexto corporativo, onde a colaboração é vital, como podemos afirmar<br/> a nossa individualidade? Um mergulho no protagonismo não violento para<br/> fortalecer vozes enquanto se cultiva a harmonia."
  },
  {
    id: 7,
    title: "talks #7",
    subtitle: "Mulheres antifrágeis: sobre construção de carreira e lifelong learning",
    description: "Para mitigar os desafios profissionais impostos às mulheres<br/> programadoras, nos debruçamos sobre suas jornadas profissionais<br/> e o desenvolvimento de uma mentalidade antifrágil frente às<br/> adversidades do setor.",
    dot: 'left'
  },
  {
    id: 8,
    title: "talks #8",
    subtitle: "Mirrortocracy  - Eliminando vieses na mobilidade profissional",
    description: "Exploramos como os vieses cognitivos limitam soluções,<br/> afetam decisões e restringem a mobilidade, e propomos<br/> estratégias para criar um espaço de trabalho mais inclusivo e<br/> equitativo."
  },
  {
    id: 9,
    title: "talks #9",
    subtitle: "Você desenvolve códigos como se desenvolve?",
    description: "A IAGen, que já está reconfigurando mercados e carreiras,<br/> também pode ser incorporada nos treinamentos <br/>corporativos para enriquecer o processo de aprendizado.<br/> Nós ensinamos como.",
    dot: 'right'
  },
  {
    id: 10,
    title: "talks #10",
    subtitle: "A mentalidade adaptativa dos humanos digitais",
    description: "Em um mundo em que softwares moldam a nossa realidade, enxergamos<br/> paralelos entre o desenvolvimento de códigos e nosso desenvolvimento<br/> profissional. Elaboramos e expandimos essa correlação para fornecer<br/> insights valiosos para as empresas."
  },
]