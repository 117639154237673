import React from 'react';
import ContactHome from '../../components/ui/ContactHome/ContactHome';

const Contact = () => {
  return (
    <main className="Contact">
      <ContactHome witdh />
    </main>
  )
}

export default Contact;
