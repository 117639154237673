import genai from "../../assets/images/trainingdetails/genai.webp";
import mindset from "../../assets/images/trainingdetails/mindset.webp";
import ppnp from "../../assets/images/trainingdetails/ppnp-details.webp";
import transformacaoDigital from "../../assets/images/trainingdetails/transformacao-digital.webp";
import upToData from "../../assets/images/trainingdetails/up-to-data.webp";

export const traininglist = [
  {
    id: 'ppnp',
    minTitle: "ppnp",
    title: "programação para <br/> não programadores",
    subtitle: "decifrando o código do mundo digital<br/>  sem precisar codificar.",
    description: 'Num universo onde tudo vira "tech" - desde sua cafeteira até estratégias corporativas bilionárias - compreender os bastidores da tecnologia deixou de ser hobby para virar ferramenta de sobrevivência. Não pretendemos transformar você no próximo gênio do Vale do Silício, mas garantimos que após este programa, reuniões técnicas deixarão de soar como língua alienígena. Porque entender tecnologia não é sobre decorar sintaxes, mas sobre dominar a lógica que move o mundo digital.',
    image: ppnp,
    fundamentals: [
      {
        title: "Nos bastidores",
        subtitle: "do código",
        describe: "Aqui desmistificamos o que acontece por trás das telas, revelando que programação é mais sobre resolver problemas do que sobre digitar comandos misteriosos. Você aprenderá a pensar como um desenvolvedor sem precisar se tornar um - porque às vezes, entender o raciocínio é mais valioso do que dominar a técnica.",
      },
      {
        title: "Arquitera",
        subtitle: "das decisões",
        describe: "Neste módulo, exploramos como sistemas são construídos e, mais importante, como escolhas técnicas impactam resultados práticos. De bancos de dados a interfaces de usuário, você descobrirá que cada decisão tecnológica carrega consequências - algumas óbvias, outras nem tanto. Porque quanto mais você entende do jogo, melhores são suas jogadas."
      },
      {
        title: "Do conceito",
        subtitle: "à prática",
        describe: "Fechamos traduzindo todo esse conhecimento para o dia a dia. Aqui você aprende a fazer as perguntas certas, identificar sinais de alerta e, principalmente, a não ser refém de jargões técnicos em discussões importantes. Porque tecnologia deve servir às pessoas, não o contrário."
      }
    ],
    duration: "12 encontros, 36h de imersão tech com linguagem acessível e prática. Para os pragmáticos, módulos independentes de 4h.",
    participants: "Online, híbrido ou presencial, unindo teoria e prática com exemplos reais. Priorizamos compreensão, não complexidade—conhecimento bom é aquele que até sua avó entende.",
    experience: "Máximo de 30 pessoas por turma para garantir que nenhuma dúvida fique sem resposta.",
  },
  {
    id: 'gen-ai',
    minTitle: "GEN AI",
    title: "Gen <br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; AI",
    description: "Destinado tanto a times de trabalho quanto às lideranças, nosso programa se propõe a desvendar as principais aplicações cotidianas do ChatGPT, como elaborar relatórios, traduzir textos e adaptá-los a diferentes contextos e finalidades, gerar insights, analisar dados e desenvolver projetos, além de pavimentar interações futuras com ferramentas correlatas.",
    image: genai,
    fundamentals: [
      {
        describe: "Onde expomos a lógica do seu funcionamento, operações rotineiras, a construção de prompts eficazes e o gerenciamento dos resultados que a plataforma fornece.",
      },
      {
        describe: "Módulo em que nos aprofundamos no refinamento dos outputs, bem como na estruturação de documentos e planos de ação, e em códigos de programação para a automação de tarefas e análise dados."
      },
      {
        describe: "Trecho final da nossa formação, quando estabelecemos comparações entre o ChatGPT e as principais alternativas disponíveis, e testamos apps embarcados como Copilot e Gemini."
      }
    ],
    duration: "3 Encontros que totalizam 8 horas de aula ou uma versão enxuta, sem a seção dedicada à análise de dados, que pode ser percorrida em 6 horas.",
    participants: "Limite máximo de 40 pessoas por turma para que possamos garantir aulas de qualidade, efetivamente personalizadas.",
    experience: "Originalmente, nosso programa gen IA é híbrido, mas o seu formato também pode ser customizado de acordo com o desejo e as possibilidades do contratante.",
  },

  {
    id: 'up-to-data',
    minTitle: "Up to data",
    title: "up to data",
    subtitle: "transformando dados<br/> em decisões inteligentes",
    description: "Em um mundo onde algoritmos determinam desde nossas playlists até decisões corporativas bilionárias, compreender dados deixou de ser diferencial para se tornar sobrevivência. Nosso programa capacita tanto times quanto lideranças a navegarem nesse oceano de informações, transformando números frios em insights que aquecem resultados. Não se trata apenas de dominar ferramentas, mas de desenvolver um novo olhar sobre a realidade - um que enxerga padrões onde outros veem apenas ruído.",
    image: upToData,
    fundamentals: [
      {
        title: "Fundamentos",
        subtitle: "da cultura de dados",
        describe: "Aqui desmistificamos o universo dos dados, revelando que por trás de cada planilha existe uma história esperando para ser contada. Você aprenderá a fazer as perguntas certas, organizar informações com método e, principalmente, a não se deixar seduzir por correlações espúrias que mais confundem do que explicam.",
      },
      {
        title: "Alquimia",
        subtitle: "dos números",
        describe: "Neste módulo, transformamos dados brutos em ouro informacional. Mergulhamos em técnicas de análise e visualização que permitem extrair significado do caos numérico. Porque não basta ter dados - é preciso saber transformá-los em argumentos irrefutáveis e decisões assertivas."
      },
      {
        title: "Do abstrato",
        subtitle: "ao concreto",
        describe: "Fechamos o ciclo aplicando o conhecimento em desafios reais, onde a teoria encontra a prática e os números ganham propósito. Aqui você aprende a comunicar descobertas de forma impactante e a implementar mudanças baseadas em evidências, não em achismos ou na última tendência do momento."
      }
    ],
    duration: "5 encontros que totalizam 20 horas de aula, com opção de um formato intensivo de 3 encontros de 4 horas focado nos fundamentos essenciais.",
    participants: "Nosso formato híbrido combina o calor dos encontros presenciais com a praticidade do digital, sendo flexível para atender às necessi-dades específicas de cada organização.",
    experience: "Limite máximo de 45 pessoas por turma para garantir atenção personalizada e participação efetiva em exercícios práticos e discussões.",
  },
  {
    id: 'mindset-agil',
    minTitle: "mindset ágil",
    title: "mindset ágil",
    subtitle: "navegando a complexidade<br/> sem perder a simplicidade.",
    description: "Em tempos onde a única constante é a mudança, cultivar agilidade como estilo de vida deixou de ser virtude para se tornar necessidade. Nosso programa, moldável como os conceitos que ensina, oferece múltiplos caminhos para desenvolver uma mentalidade adaptativa. Não prometemos receitas prontas - até porque elas costumam vencer antes mesmo de serem implementadas. Em vez disso, construímos junto com você as bases para prosperar na era da incerteza.",
    image: mindset,
    fundamentals: [
      {
        title: "Fundamentos",
        subtitle: "da agilidade",
        describe: "Desmontamos o complexo quebra-cabeça da agilidade em peças compreensíveis, revelando que por trás de cada framework existe um princípio fundamental: a capacidade de aprender e se adaptar continuamente. Aqui, você descobrirá que ser ágil vai muito além de fazer reuniões em pé ou colar post-its na parede.",
      },
      {
        title: "Prática",
        subtitle: "da adaptabilidade",
        describe: "Neste módulo, transformamos teoria em ação. Exploramos como timeboxing, iterações e feedback contínuo podem revolucionar não apenas projetos, mas a forma como enxergamos desafios. Porque agilidade sem prática é como GPS sem satélite - tecnicamente correto, mas praticamente inútil."
      },
      {
        title: "Cultura",
        subtitle: "da experimentação",
        describe: "Fechamos conectando os pontos entre mentalidade e resultados. Aprendemos a transformar erros em dados, hipóteses em experimentos e incertezas em oportunidades. Afinal, num mundo em constante mudança, quem não experimenta está condenado a repetir fórmulas ultrapassadas."
      }
    ],
    duration: "Imersão Completa: 40h em 10 encontros Jornada Focada: 16h em 4 encontros Workshops: 4h em tópicos específicos Laboratórios Práticos: 8h de experimentação intensiva",
    participants: "Nosso programa se adapta ao seu contexto: presencial, remoto ou híbrido. A forma segue a função, garantindo o melhor formato para você.",
    experience: "Como a transformação acontece no equilíbrio entre individualidade e colaboração temos desenhos para turma de 30 até 300 pessoas.",
  },
  {
    id: 'transformacao-digital',
    minTitle: "transformação digital",
    title: "transformação digital",
    subtitle: "conhecimento para um mundo <br/> que já chegou.",
    description: "Em um cenário onde todo mundo fala em transformação digital mas poucos compreendem sua verdadeira essência, desenvolvemos uma jornada que vai muito além de tecnologias e buzzwords. Não se trata apenas de digitalizar processos - é sobre repensar a própria forma como existimos, trabalhamos e criamos valor em um mundo onde o analógico e o digital não são mais dimensões separadas, mas faces da mesma realidade. Este programa é para quem busca não apenas entender a transformação digital, mas protagonizá-la.",
    image: transformacaoDigital,
    fundamentals: [
      {
        title: 'Do analógico ao digital:',
        subtitle: 'Uma Jornada Interior',
        describe: "Começamos desconstruindo mitos. Aqui, você descobrirá que a verdadeira transformação digital começa no autoconhecimento e na forma como estruturamos pensamentos. Exploramos desde a sociedade do cansaço até a antifragilidade, porque antes de transformar negócios, precisamos transformar mindsets.",
      },
      {
        title: 'Arquitetura',
        subtitle: 'da transformação',
        describe: "Neste módulo, mergulhamos nas estruturas que sustentam a mudança. Da comunicação não violenta ao design thinking, da inteligência artificial aos ciclos ágeis. Você aprenderá que tecnologia é meio, não fim - e que a verdadeira inovação nasce da interseção entre pessoas, processos e possibilidades."
      },
      {
        title: 'Da teoria à',
        subtitle: 'prática transformadora',
        describe: "Fechamos o ciclo aplicando conceitos em realidades concretas. Aqui, métricas encontram significado, metodologias viram ação e a transformação digital deixa de ser conceito para virar prática. Porque no final, é sobre criar valor real em um mundo cada vez mais complexo."
      }
    ],
    duration: "• 24 encontros que totalizam 36 horas de imersão profunda <br/>• Cada encontro de 1.5 hora combina teoria, prática e reflexão<br/> • Estrutura modular que permite absorção gradual e consistente.",
    participants: "• Formato híbrido, refletindo a transfor-mação digital <br/>• Alternância entre momentos síncronos e assíncronos <br/>• Hands-on em ferramentas e métodos • Discussões que desafiam o status quo",
    experience: "• Turmas de até 30 pessoas para discussões aprofundadas <br/>• Diversidade de perfis para ampliar perspectivas <br/>• Para líderes, gestores e profissi-onais da transformação digital.",
  }
]