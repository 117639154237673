import { motion } from 'framer-motion';
import React, { useState } from 'react';
import minLogo from '../../../assets/images/contact/min-logo.png';
import './ContactHome.scss';
import useIsDesktop from '../../../hooks/useIsDesktop';

const ContactHome = ({ text, witdh }) => {
  const [formData, setFormData] = useState({
    nome: "",
    empresa: "",
    celular: "",
    email: "",
    mensagem: "",
  });

  const [errors, setErrors] = useState({});
  const isDesktop = useIsDesktop();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    const newErrors = {};
    if (!formData.nome.trim()) newErrors.nome = "Nome completo é obrigatório.";
    if (!formData.empresa.trim()) newErrors.empresa = "Empresa é obrigatória.";
    if (!formData.celular.trim()) newErrors.celular = "Celular é obrigatório.";
    if (!formData.email.trim()) {
      newErrors.email = "E-mail é obrigatório.";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "E-mail inválido.";
    }
    if (!formData.mensagem.trim())
      newErrors.mensagem = "Mensagem é obrigatória.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length === 0) {
      alert("Formulário enviado com sucesso!");
      setFormData({
        nome: "",
        empresa: "",
        celular: "",
        email: "",
        mensagem: "",
      });
      setErrors({});
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className={`ContactHome ${text ? "with-text" : ""} ${witdh ? "with-witdh" : ""}`}>
      <div className="contact-home-container">
        {isDesktop ? (
          <motion.div
            className="header-contact"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 1 }}
          >
            <div>
              {text && (
                <motion.h2
                  initial={{ y: -50, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.8 }}
                  viewport={{ once: true, amount: 0.5 }}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              )}
              {!text && (
                <>
                  <motion.h2
                    initial={{ y: -50, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.8 }}
                    viewport={{ once: true, amount: 0.5 }}
                  >
                    Você ainda <strong>está aí?</strong>
                  </motion.h2>
                  <motion.h3
                    initial={{ y: -30, opacity: 0 }}
                    whileInView={{ y: 0, opacity: 1 }}
                    viewport={{ once: true, amount: 0.4 }}
                    transition={{ duration: 0.8, delay: 0.4 }}
                  >
                    Esse textão poderia ser um café...
                  </motion.h3>
                </>
              )}
              <motion.p
                className="description"
                initial={{ y: -20, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.8, delay: 0.4 }}
              >
                Escreve pra gente!
              </motion.p>
            </div>
            <motion.img
              src={minLogo}
              alt="minLogo"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 1, delay: 0.4 }}
            />
          </motion.div>
        ) : (
          <div className="header-contact">
            <div>
              {text && <h2 dangerouslySetInnerHTML={{ __html: text }} />}
              {!text && (
                <>
                  <h2>
                    Você ainda <strong>está aí?</strong>
                  </h2>
                  <h3>Esse textão poderia ser um café...</h3>
                </>
              )}
              <p className="description">Escreve pra gente!</p>
            </div>
            <img src={minLogo} alt="minLogo" />
          </div>
        )}

        <div
          className="container-contact"
        >
          <form className="custom-form" onSubmit={handleSubmit}>
            <div className="form-row">
              <div className={`form-group ${errors.nome ? "error" : ""}`}>
                <input
                  type="text"
                  name="nome"
                  placeholder="NOME COMPLETO"
                  value={formData.nome}
                  onChange={handleChange}
                />
                {errors.nome && <span className="error">{errors.nome}</span>}
              </div>

              <div className={`form-group ${errors.empresa ? "error" : ""}`}>
                <input
                  type="text"
                  name="empresa"
                  placeholder="EMPRESA"
                  value={formData.empresa}
                  onChange={handleChange}
                />
                {errors.empresa && <span className="error">{errors.empresa}</span>}
              </div>
            </div>

            <div className="form-row">
              <div className={`form-group ${errors.celular ? "error" : ""}`}>
                <input
                  type="text"
                  name="celular"
                  placeholder="CELULAR"
                  value={formData.celular}
                  onChange={handleChange}
                />
                {errors?.celular && <span className="error">{errors.celular}</span>}
              </div>

              <div className={`form-group ${errors.email ? "error" : ""}`}>
                <input
                  type="email"
                  name="email"
                  placeholder="E-MAIL"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
            </div>

            <div className="form-row">
              <div className={`form-group textarea-group ${errors.mensagem ? "error" : ""}`}>
                <textarea
                  name="mensagem"
                  placeholder="DEIXE SUA MENSAGEM AQUI"
                  value={formData.mensagem}
                  onChange={handleChange}
                />
                {errors.mensagem && <span className="error">{errors.mensagem}</span>}
              </div>

              <button type="submit" className="btn-enviar">
                ENVIAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactHome;
