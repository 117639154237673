import { motion } from 'framer-motion';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Footer.scss';
import useIsDesktop from '../../../hooks/useIsDesktop';

const Footer = () => {
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  return (
    <footer className="Footer">
      {isDesktop ? (
        <motion.div
          className="footer-content"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeInOut" }}
          viewport={{ once: true }}
        >
          <div className="footer-left">
            <motion.div
              className="footer-links"
              initial={{ opacity: 0, x: -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.2, ease: "easeInOut", delay: 0.4 }}
              viewport={{ once: true }}
            >
              <div className="footer-logo">
                <motion.h1
                  initial={{ scale: 0.8 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.8, ease: "easeOut" }}
                >
                  MASTERTECH
                </motion.h1>
              </div>
              <ul className='button-list'>
                <li onClick={() => { navigate('mastertech-who') }}>MASTERTECH WHO?</li>
                <li onClick={() => { navigate('cases') }}>CASES</li>
              </ul>
              <ul className='button-list'>
                <li onClick={() => { navigate('trilhas-formativas') }}>TRILHAS FORMATIVAS</li>
                <li onClick={() => { navigate('team-buildings') }}>TEAM BUILDING</li>
                <li onClick={() => { navigate('consultoria') }} >CONSULTORIA</li>
                <li onClick={() => { navigate('mtalks') }}>M/TALKS</li>
              </ul>
              <ul>
                <li>Av. Brigadeiro Luis Antônio, 2696</li>
                <li>Bela Vista</li>
                <li>CEP 01402-000</li>
                <li>TEL +55 11 91952-2455</li>
              </ul>
            </motion.div>
            <motion.div
              className="footer-logos"
              initial={{ opacity: 0, y: 50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1, ease: "easeInOut", delay: 0.4 }}
              viewport={{ once: true }}
            >
              <span className="ic--baseline-whatsapp" onClick={() => window.open('https://api.whatsapp.com/send?phone=+5511919522455&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20os%20cursos%20oferecidos%20pela%20Mastertech.', '_blank')} />
              <span className="mdi--instagram" onClick={() => window.open('https://www.instagram.com/mastertech.tech/', '_blank')} />
              <span className="mingcute--youtube-fill" onClick={() => window.open('https://www.youtube.com/@Mastertech-escola', '_blank')} />
              <span className="ri--linkedin-fill" onClick={() => window.open('https://www.linkedin.com/school/mastertech.tech/posts/?feedView=all', '_blank')} />
            </motion.div>
          </div>
        </motion.div>
      ) : (
        <div className="footer-content">
          <div className="footer-left">
            <div className="footer-links">
              <div className="footer-logo">
                <h1>MASTERTECH</h1>
              </div>
              <ul className='button-list'>
                <li onClick={() => { navigate('mastertech-who') }}>MASTERTECH WHO?</li>
                <li onClick={() => { navigate('cases') }}>CASES</li>
              </ul>
              <ul className='button-list'>
                <li onClick={() => { navigate('trilhas-formativas') }}>TRILHAS FORMATIVAS</li>
                <li onClick={() => { navigate('team-buildings') }}>TEAM BUILDING</li>
                <li onClick={() => { navigate('consultoria') }} >CONSULTORIA</li>
                <li onClick={() => { navigate('mtalks') }}>M/TALKS</li>
              </ul>
              <ul>
                <li>Av. Brigadeiro Luis Antônio, 2696</li>
                <li>Bela Vista</li>
                <li>CEP 01402-000</li>
                <li>TEL +55 11 91952-2455</li>
              </ul>
            </div>
            <div className="footer-logos">
              <span className="ic--baseline-whatsapp" onClick={() => window.open('https://api.whatsapp.com/send?phone=+5511919522455&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20os%20cursos%20oferecidos%20pela%20Mastertech.', '_blank')} />
              <span className="mdi--instagram" onClick={() => window.open('https://www.instagram.com/mastertech.tech/', '_blank')} />
              <span className="mingcute--youtube-fill" onClick={() => window.open('https://www.youtube.com/@Mastertech-escola', '_blank')} />
              <span className="ri--linkedin-fill" onClick={() => window.open('https://www.linkedin.com/school/mastertech.tech/posts/?feedView=all', '_blank')} />
            </div>
          </div>
        </div>
      )}

      {isDesktop && (
        <motion.div
          className="footer-bottom"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, ease: "easeInOut", delay: 0.4 }}
          viewport={{ once: true }}
        >
          <p role='button' onClick={() => window.open('/politica-de-privacidade/', '_blank')}>Políticas de privacidade e portal do titular</p>
          <p role='button' onClick={() => window.open('/termos-condicoes/', '_blank')}>Mastertech 2025. Todos os direitos reservados.</p>
        </motion.div>
      )}

      {!isDesktop && (
        <div className="footer-bottom">
          <p role='button' onClick={() => window.open('/politica-de-privacidade/', '_blank')}>Políticas de privacidade e portal do titular</p>
          <p role='button' onClick={() => window.open('/termos-condicoes/', '_blank')}>Termos e Condições</p>
        </div>
      )}
    </footer>
  );
};

export default Footer;
